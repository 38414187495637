import { render, staticRenderFns } from "./wall.vue?vue&type=template&id=d4011526&scoped=true"
import script from "./wall.vue?vue&type=script&lang=js"
export * from "./wall.vue?vue&type=script&lang=js"
import style0 from "./wall.vue?vue&type=style&index=0&id=d4011526&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4011526",
  null
  
)

export default component.exports